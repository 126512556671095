.button-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.download-upload-buttons {
    display: flex;
    gap: 10px;
}

.my-search-input {
    width: 100%;
    margin-bottom: 10px;
}

.custom-bar{
  display: flex;
  flex-direction: row;
  width: 25%;
}

.icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.summary-card {
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }

  .filter-toggle {
    display: flex;
    margin-top: 5px;
    padding: 5px 0;
    gap: 8px;
  }
  
  .filter-toggle input[type="checkbox"] {
    margin: 0; 
    position: static; 
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  
  .filter-toggle label {
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    color: #495057;
    white-space: nowrap;
    margin-bottom: 0;
  }
  
  .filter-toggle:hover {
    background-color: #f8f9fa;
    border-radius: 4px;
  }
  
  .summary-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #495057;
  }
  .summary-value {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #212529;
  }
  .summary-label {
    font-size: 14px;
    color: #6c757d;
  }

.download-button,
.upload-button {
    display: flex;
    align-items: center;
    gap: 5px;
    border: none;
}

.filter-section {
    margin-bottom: 10px;
  }

.custom-container {
    padding-top: 20px;
    width: 100%;
    padding-right: 100px;
    padding-left: 100px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
}

.filter-group {
    display: flex;
    gap: 5px;
    margin-bottom: 15px;
    align-items: center;
    flex-wrap: wrap;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
}
.pagination button {
  min-width: 32px;
}

.filter-group select {
  flex: 1; 
  min-width: 120px; 
}

.select-dropdown{
  flex: 1;
  min-width: 120px;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }

  .tab-content {
    display: none;
    padding: 15px 0;
  }
  .tab-content.active {
    display: block;
  }

  .submission-card {
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    margin-bottom: 15px;
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out;
  }
  .submission-card:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  }

  .submission-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
  }

  .submission-title {
    font-weight: bold;
    font-size: 16px;
  }

  .station-badge {
    display: inline-block;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 14px;
    color: white;
    min-width: 80px;
    text-align: center;
    background-color: aqua;
  }
  .submission-meta {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
  .submission-meta-item {
    display: flex;
    flex-direction: column;
    min-width: 100px;
  }
  .meta-label {
    font-size: 12px;
    color: #6c757d;
  }
  .meta-value {
    font-size: 14px;
  }
  .submission-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e9ecef;
  }
  .field-item {
    margin-bottom: 8px;
  }
  .field-label {
    font-size: 12px;
    color: #6c757d;
    margin-bottom: 2px;
  }
  .field-value {
    font-size: 14px;
    word-break: break-word;
  }
  .view-more {
    text-align: center;
    margin-top: 10px;
    color: #0d6efd;
    font-size: 14px;
    cursor: pointer;
  }

@media (max-width: 768px) {
    .custom-container {
        padding-top: 20px;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
    }

    .select-dropdown{
     width: 100%;
    }

    .my-search-input {
        width: 100%;
    }

    .button-bar {
        flex-direction: column;
        gap: 10px;
    }

    .download-upload-buttons {
        justify-content: space-between;
    }

    .dashboard-grid {
        grid-template-columns: 1fr;
    }

    .download-upload-buttons {
        justify-content: space-between;
    }

    .search-input {
        width: 100%;
    }

    .driver-details {
        grid-template-columns: 1fr;
    }

    .driver-meta {
        flex-direction: column;
        gap: 5px;
    }

    .filter-group {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; 
    }

    .custom-bar{
        width: 100%;
    }
    .submission-fields {
        grid-template-columns: 1fr;
      }
      .submission-meta {
        flex-direction: column;
        gap: 5px;
      }
      .submission-meta-item {
        min-width: auto;
      }
      .button-group {
        flex-wrap: wrap;
      }
}