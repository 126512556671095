.formbadge {
    display: inline-block;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 14px;
    color: white;
    min-width: 80px;
    text-align: center;
  }
  
  /* Modal styling */
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .modal-backdrop.active {
    opacity: 1;
    visibility: visible;
  }

  .modal-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 20px;
    position: relative;
    transform: translateY(20px);
    transition: transform 0.3s ease;
  }

  .modal-backdrop.active .modal-container {
    transform: translateY(0);
  }

  .modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #6c757d;
  }

  .modal-header {
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e9ecef;
    position: relative;
  }

  .modal-title {
    font-size: 24px;
    font-weight: bold;
    margin-right: 30px; /* Space for close button */
  }

  .modal-body {
    margin-bottom: 20px;
  }

  .modal-footer {
    padding-top: 15px;
    border-top: 1px solid #e9ecef;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  /* Form styling */
  .form-section {
    margin-bottom: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 15px;
  }

  .form-section-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e9ecef;
  }

  .form-field {
    margin-bottom: 20px;
  }

  .form-field:last-child {
    margin-bottom: 0;
  }

  .form-field-label {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 15px;
  }

  .form-field-description {
    font-size: 12px;
    color: #6c757d;
    margin-bottom: 5px;
  }

  .form-field-value {
    background-color: white;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    font-size: 14px;
  }

  .form-field-value.textarea {
    min-height: 80px;
    white-space: pre-line;
  }

  .result-label {
    display: inline-block;
    padding: 4px 10px;
    border-radius: 4px;
    margin-right: 10px;
    font-weight: 600;
    font-size: 14px;
  }

  .result-pass {
    background-color: #d1e7dd;
    color: #0f5132;
  }

  .result-fail {
    background-color: #f8d7da;
    color: #842029;
  }

  .result-na {
    background-color: #e2e3e5;
    color: #41464b;
  }

  .form-info {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
  }

  .form-info-item {
    flex: 1;
    min-width: 150px;
  }

  .form-info-label {
    font-size: 12px;
    color: #6c757d;
    margin-bottom: 3px;
  }

  .form-info-value {
    font-weight: 600;
    font-size: 16px;
  }

  .form-status {
    display: inline-block;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
  }

  .status-safe {
    background-color: #d1e7dd;
    color: #0f5132;
  }

  .status-unsafe {
    background-color: #f8d7da;
    color: #842029;
  }

  .form-title{
    font-size: 20px;
  }

  .form-action-button {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    border: none;
  }

  .primary-button {
    background-color: #0d6efd;
    color: white;
  }

  .cancel-button {
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    color: #212529;
  }